// .create-client-dialog {
//   .MuiPaper-root {
//     width: 800px  ;
//     margin: 0;
//   }
// }

.add-client-details {
  .MuiOutlinedInput-input {
    padding: 5px 8px 5px 8px;
    background-color: white;
    margin-top: 5px;
    border-radius: 6px;
    border: 1px solid #d1d5db;
    font-size: 16px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-radius: 0px !important;
    border: 0;
  }
  .datePicker {
    .MuiStack-root {
      overflow: hidden;
    }
    .MuiOutlinedInput-input {
      padding: 5px 8px 5px 8px;
      background-color: white;
      margin-top: 0px;
      border: 0;
      font-size: 16px !important;
    }
    margin-top: 5px;
    overflow: hidden;
  }
  .css-134uato-MuiFormControl-root-MuiTextField-root {
    background: white;
    border-radius: 6px;
    border: 1px solid #d1d5db;
  }
}
