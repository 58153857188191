.CalendarWidget {
  .Widget--Header {
    .MuiOutlinedInput-input {
      padding: 9px 12px 9px 8px;
      border-radius: 10px !important;
      background: #e7f6ff;
    }
    .MuiOutlinedInput-notchedOutline {
      border-width: 0;
    }
  }
}
.Days--Container {
  display: grid;
  gap: 2px;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.Content--Days {
  padding: 10px 20px;
  .Day--Item {
    padding: 0.625rem 0.9375rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      text-align: center;
      font-size: 12px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #73589b;
    }
    p {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .hasAppointment {
      padding: 2px;
      background-color: red;
      border-radius: 50%;
    }
  }
  .SelectedDay {
    background-color: #f4edff;
    border-radius: 10px;
    border: 1px solid #73589b;
    .span {
      color: #73589b;
    }
  }
}

.Content--Data {
  display: grid;
  padding: 10px 20px;
  gap: 5px;
  .Data--Item {
    border-radius: 0.625rem;
    border: 1px solid #f5f5f5;
    padding: 10px;

    display: flex;
    align-items: start;
    gap: 10px;
  }

  .Item--details {
    display: flex;
    flex-direction: column;
    
    & > p {
      color: #000;
      font-size: 1rem;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      p {
        color: #000;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.21px;
      }
    }
  }
}
