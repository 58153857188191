.clinicalNote--ClientItem {
  border: 1px solid #eeeeee;
  border-radius: 12px;
  background: #fff;

  .ClientItem--Header {
    img {
      border-radius: 50%;
    }
    h2 {
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      margin-bottom: 0 !important;
    }
    p {
      color: #6a6c6a;
      font-size: 16px;
    }
  }
}
.clinicalNote--Grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  margin: 20px 0;
  width: 100%;
  padding: 25px;
}
