.recommendation-assessment {
  .MuiPaper-root {
    min-width: 800px !important;
    height: 750px;
  }
}
.disclaimer-modal {
  .MuiPaper-root {
    min-width: 1000px !important;
    // height: 80vh;
  }
}
@media screen and (max-width: 1024px) {
  .recommendation-assessment {
    .MuiPaper-root {
      min-width: 95% !important;
      height: 600px;
    }
  }
  .disclaimer-modal {
    .MuiPaper-root {
      min-width: 95% !important;
      height: 90vh;
    }
  }
}

.clinicalnotes-submit-dialog {
  .MuiPaper-root {
    width: 377px !important;
    border-radius: 10px;
  }
  .save {
    border-radius: 10px !important;
    border: none;
    background-color: #ffffff !important;
    display: flex !important;
    height: 50px;
    padding: 14px 24px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #344054 !important;
    text-transform: capitalize !important;
    font-size: 16px;
    border: solid 1px #d0d5dd;
    &:hover {
      background-color: darken(#ffffff, 5%) !important;
    }
  }
  .submit {
    border-radius: 10px !important;
    border: none;
    background-color: #079455 !important;
    display: flex !important;
    height: 50px;
    padding: 14px 24px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;

    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 16px;

    &:hover {
      background-color: darken(#079455, 5%) !important;
    }
  }
  .delete {
    border-radius: 10px !important;
    border: none;
    background-color: #d92d20 !important;
    display: flex !important;
    height: 50px;
    padding: 14px 24px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;

    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 16px;

    &:hover {
      background-color: darken(#d92d20, 5%) !important;
    }
  }
  .archive {
    border-radius: 10px !important;
    border: none;
    background-color: var(--primary-color) !important;
    display: flex !important;
    height: 50px;
    padding: 14px 24px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;

    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 16px;

    &:hover {
      background-color: var(--primary-color-darken) !important;
    }
  }
}
.clinicalnotes-submit-save {
  .save {
    border-radius: 10px !important;
    border: none;
    background: white !important;
    display: flex !important;
    height: 50px;
    padding: 14px 24px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: #344054 !important;
    text-transform: capitalize !important;
    font-size: 16px;
    border: solid 1px var(--primary-color);
    &:hover {
      background-color: darken(#ffffff, 5%) !important;
    }
  }
  .submit {
    border-radius: 10px !important;
    border: none;
    background: #079455 !important;
    display: flex !important;
    height: 50px;
    padding: 14px 24px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 16px;

    &:hover {
      background-color: darken(#079455, 5%) !important;
    }
  }
}
.create-note-button {
  position: relative !important;
}
@media screen and (max-width: 1024px) {
  .create-note-button {
    position: fixed !important;
    bottom: 30px;
    right: 10px;
  }
}
