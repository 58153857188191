.Notetaker--Modal {
  visibility: hidden;
  position: fixed;
  top: 0;
  right: -1000px;
}

.progress--container {
  width: 100% !important;
  max-width: 100% !important;
  height: 400px !important;
  border-radius: 10px !important;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal--Open {
  visibility: visible;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5490196078);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.Modal--Open .close--button {
  position: absolute;
  top: -30px;
  right: -30px;
  border-radius: 50%;
  background-color: #fff;
  color: #111;
  aspect-ratio: 1/1;
  min-width: 0 !important;
  padding: 10px;
}
.Modal--Open .close--button:hover {
  background-color: #e4e4e4;
}
.Modal--Open .close--button svg {
  width: 15px;
  height: 15px;
}
.Modal--Open .Notetaker--Content {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 670px;
}
.Modal--Open .Notetaker--Content .App > label {
  width: 100% !important;
  max-width: 100% !important;
  height: 350px !important;
  border-radius: 10px !important;
  border: 2px dashed var(--primary-color-opacity50) !important;
  margin: auto;
}
.Modal--Open .Notetaker--Content .App > label svg {
  display: none !important;
}
.Modal--Open .Notetaker--Content .App > label > div {
  align-items: center !important;
  justify-content: center !important;
}
@media screen and (max-width: 1024px) {
  .Modal--Open .Notetaker--Content {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95vw;
    height: 600px;
  }
  .Modal--Open .Notetaker--Content .App > label {
    width: 100% !important;
    max-width: 100% !important;
    height: 150px !important;
    border-radius: 10px !important;
    border: 2px dashed var(--primary-color-opacity50) !important;
    margin: auto;
  }
  .Modal--Open .Notetaker--Content .App > label svg {
    display: none !important;
  }
  .Modal--Open .Notetaker--Content .App > label > div {
    align-items: center !important;
    justify-content: center !important;
  }
}
.Modal--Open .Notetaker--NoteArea {
  box-shadow: 0px 0px 40px 0px rgba(87, 88, 90, 0.2);
  border-radius: 10px;
  padding: 10px;
}
.Modal--Open .Notetaker--Options {
  display: flex;
  align-items: center;
  gap: 2px;
  border-top: 1px solid #f2f4f7;
}
.Modal--Open .Notetaker--Options button {
  background: #fff;
  color: #667085;
  border: none;
  padding: 10px;
}

.fileUpload--Modal .MuiDialog-paper {
  height: 550px !important;
  width: 800px !important;
}
.fileUpload--Modal .Modal--Open {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fileUpload--Modal .Modal--Open .upload--Area .App {
  background-color: #fff;
}
.fileUpload--Modal .Modal--Open .upload--Area .App > label {
  width: 750px !important;
  max-width: 100% !important;
  height: 400px !important;
  border-radius: 10px !important;
  border: 2px dashed var(--primary-color-opacity50) !important;
  margin: auto;
  background-color: #fff;
}
.fileUpload--Modal .Modal--Open .upload--Area .App > label svg {
  display: none !important;
}
.fileUpload--Modal .Modal--Open .upload--Area .App > label > div {
  align-items: center !important;
  justify-content: center !important;
}

.fileUpload--Modal {
  width: 100% !important;
  max-width: 100% !important;
  height: 200px !important;
  border-radius: 10px !important;
  border: 2px dashed var(--primary-color-opacity50) !important;
  margin: auto;
}
.fileUpload--Modal .file-types {
  display: none;
}
.fileUpload--Modal div {
  align-items: center !important;
  justify-content: center !important;
}
.fileUpload--Modal span {
  font-size: 16px !important;
}
.fileUpload--Modal #text {
  font-size: 16px !important;
}
.fileUpload--Modal span > span {
  font-size: 16px !important;
}
.fileUpload--Modal svg {
  display: none !important;
}/*# sourceMappingURL=FloatingService.css.map */