.NotificationRowCard {
  padding: 20px;
  display: flex;
  align-items: start;
  gap: 15px;

  img {
    width: 40px;
    min-width: 40px;
  }
  .newAppointmentIcon {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f5f5f5;

    svg {
      width: 21px;
      height: 21px;
      color: #153300;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .NotificationRowCard--Content {
    .header {
      color: #0e0f0c;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.21px;
    }
    .review {
      color: #000;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 145.023%;
      letter-spacing: 0.21px;

      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .Content--Actions {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 8px;
      button {
        border: none;
        padding: 10px;
        border-radius: 5px;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
      }

      .ProfileCTA {
        background-color: #f6fdf0;
        color: #153300;
        text-transform: capitalize;
      }
      .JoinCTA {
        background-color: #73589b;
        color: #fff;
      }
    }
  }

  .newAppointment--details {
    margin-top: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      color: #000;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.21px;
    }
  }
}
