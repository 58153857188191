.DarkDropdown{
    .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper{
      border-radius: 10px !important;
    }
    .MuiMenu-list{
      background: #000;
      border-radius: 10px;
    }
    li{
        color: #FFF;
    }
  }