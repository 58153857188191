.Notetaker--Modal {
  visibility: hidden;
  position: fixed;
  top: 0;
  right: -1000px;
}
.progress--container {
  width: 100% !important;
  max-width: 100% !important;
  height: 400px !important;
  border-radius: 10px !important;
  // border: 2px dashed rgba(157, 137, 188, 0.6) !important;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal--Open {
  visibility: visible;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0000008c;
  backdrop-filter: blur(4px);
  .close--button {
    position: absolute;
    top: -30px;
    right: -30px;
    border-radius: 50%;
    background-color: #fff;
    color: #111;
    aspect-ratio: 1/1;
    min-width: 0 !important;
    padding: 10px;
    &:hover {
      background-color: #e4e4e4;
    }
    svg {
      width: 15px;
      height: 15px;
    }
  }
  .Notetaker--Content {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    height: 670px;

    .App {
      & > label {
        width: 100% !important;
        max-width: 100% !important;
        height: 350px !important;
        border-radius: 10px !important;
        border: 2px dashed var(--primary-color-opacity50) !important;
        margin: auto;

        svg {
          display: none !important;
        }

        & > div {
          align-items: center !important;
          justify-content: center !important;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .Notetaker--Content {
      background-color: #fff;
      padding: 10px;
      border-radius: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 95vw;
      height: 600px;

      .App {
        & > label {
          width: 100% !important;
          max-width: 100% !important;
          height: 150px !important;
          border-radius: 10px !important;
          border: 2px dashed var(--primary-color-opacity50) !important;
          margin: auto;

          svg {
            display: none !important;
          }

          & > div {
            align-items: center !important;
            justify-content: center !important;
          }
        }
      }
    }
  }

  .Notetaker--NoteArea {
    box-shadow: 0px 0px 40px 0px #57585a33;
    border-radius: 10px;
    padding: 10px;
  }
  .Notetaker--Options {
    display: flex;
    align-items: center;
    gap: 2px;
    border-top: 1px solid #f2f4f7;
    button {
      background: #fff;
      color: #667085;
      border: none;
      padding: 10px;
    }
  }
}

.fileUpload--Modal {
  .MuiDialog-paper {
    height: 550px !important;
    width: 800px !important;
  }
  .Modal--Open {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .upload--Area {
      .App {
        background-color: #fff;
        & > label {
          width: 750px !important;
          max-width: 100% !important;
          height: 400px !important;
          border-radius: 10px !important;
          border: 2px dashed var(--primary-color-opacity50) !important;
          margin: auto;
          background-color: #fff;

          svg {
            display: none !important;
          }

          & > div {
            align-items: center !important;
            justify-content: center !important;
          }
        }
      }
    }
  }
}

.fileUpload--Modal {
  width: 100% !important;
  max-width: 100% !important;
  height: 200px !important;
  border-radius: 10px !important;
  border: 2px dashed var(--primary-color-opacity50) !important;
  margin: auto;
  .file-types {
    display: none;
  }
  div {
    align-items: center !important;
    justify-content: center !important;
  }
  span {
    font-size: 16px !important;
  }
  #text {
    font-size: 16px !important;
  }
  span > span {
    font-size: 16px !important;
  }
  svg {
    display: none !important;
  }
}
