.SessionTranscript--ScrollBar{
  overflow-y: scroll;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e1e1e1; /* Color of the scrollbar handle */
    border-radius: 4px; /* Border radius of the scrollbar handle */
  }
}
