.ArticleCard {
  .MuiCardContent-root {
    
    .MuiTypography-span {
      color: #000;
      font-size: 14px !important;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 145.023%;
      letter-spacing: 0.21px;
      margin-bottom: 10px;
    }

    .MuiTypography-h6 {
      color: #000;
      font-size: 16px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .ArticleCard--ViewsNComments {
        margin-top: 12px;
      svg {
        color: #73589b;
        width: 15px;
        height: 15px;
      }

      span {
        color: #6a6c6a;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 145.023%;
        letter-spacing: 0.21px;
      }
    }
  }

  .MuiCardActions-root{
    padding-bottom: 24px;
  }
}
