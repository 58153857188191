.ExpertHelpCenter--Dashboard {
  .ExpertHelpCenter--Header {
    h2 {
      color: #000;
      font-size: 22px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }
  }
  .ExpertHelpCenter--documents {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 20px;
    margin-top: 20px;
    .line-clamp {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.line-text-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ExpertHelpCenter--Options {
  justify-content: space-between;

  .Options {
    .Option--Item {
      cursor: pointer;
      padding: 11px 25px;
      border-radius: 30px;
      border: 1px solid #d9d9d9;
      background: #f5f5f5;
      color: #6a6c6a;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .SelectedItem {
      background: #73589b;
      color: #fff;
    }
  }
}

.upload-button {
  display: flex;
  align-items: center;
  border: 1px solid #e3e3e4;
  border-radius: 4px;
  margin-top: 15px;
}

.upload-button-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-right: 1px solid #e3e3e4;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: 10px;
  background-color: #f3f3f3;
}

.upload-button-icon {
  margin-right: 4px;
}

.upload-button-input {
  display: none;
}

.selected-files {
  margin-top: 10px;
  width: 70%;
}

.file-name {
  margin-bottom: 4px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* margin-right: 20px; */
  width: 100%;
}

.ExpertHelpCenter--Dashboard {
  background: white;
  .swiper {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.Help-center--card {
  background: #fff;
}
.Help-center--card:hover {
  background: #f6f6f6;
}
.Help-center-video-dialog {
  .MuiPaper-root {
    min-width: 800px !important;
  }
}
@media screen and (max-width: 1024px) {
  .Help-center-video-dialog {
    .MuiPaper-root {
      min-width: 95vw !important;
      padding: 0px !important;
    }
    .MuiDialogContent-root {
      padding: 10px !important;
    }
  }
}
