.recommender-history-dialog {
  .MuiDialog-paper {
    min-height: 400px !important;
    min-width: 800px !important;
    max-width: 1200px !important;
  }
  .MuiAccordion-root {
    height: fit-content !important;
  }
}

@media screen and (max-width: 1024px) {
  .recommender-history-dialog {
    .MuiDialog-paper {
      min-height: 600px !important;
      min-width: 95% !important;
      max-width: 95% !important;
    }
    .MuiAccordion-root {
      height: fit-content !important;
    }
  }
  .create-client-dialog {
    .MuiDialog-paper {
      min-height: 500px !important;
      min-width: 95% !important;
      max-width: 95% !important;
    }
    .MuiAccordion-root {
      height: fit-content !important;
    }
  }
}
