.TicketDetails--TableRow {
  position: relative;
  height: 68.8px;
  &:first-child {
    border-top-left-radius: 10px;
  }

  .Table--SRNO {
    p {
      color: #000;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .Table--Topic {
    div {
      display: flex;
      flex-direction: column;
      gap: 5px;
      p {
        color: #000;
        font-size: 16px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        white-space: nowrap;
      }

      span {
        color: #656565;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .Table--Detail {
    p {
      color: #656565;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }

  .Ticket--Resolved {
    display: inline-flex;
    height: 30px;
    padding: 4px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    color: #fff;
  }

  .Ticket--Resolved__Oultine {
    display: inline-flex;
    height: 30px;
    padding: 4px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
  }

  .Ticket--Reply {
    display: inline-flex;
    padding: 2px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 30px;
    background: rgba(157, 234, 102, 0.2);
  }
}

.ReplyTicket--Dialog {
  .MuiPaper-root {
    max-width: 1080px !important;
    width: 95% !important;
    max-height: 700px !important;
    height: 95% !important;
  }
  .MuiDialogTitle-root {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #73589b;

    button {
      min-width: auto;
      svg {
        fill: #fff;
        width: 28px;
        height: 28px;
      }
    }
  }
  .MuiDialogContent-root {
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    .HelpChat--Header {
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 10;
      padding: 10px 0;
      background: linear-gradient(
        90deg,
        rgba(250, 233, 235, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      padding: 10px 24px;

      p {
        color: #000;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      span {
        color: #656565;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .HelpChat--Chats {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 20px 24px 0px 24px;
      //height: 100%;

      .sentChat,
      .receivedChat {
        align-items: flex-end !important;

        .messagesArea {
          display: flex;
          align-items: start;
          gap: 10px;
          width: 100%;
          div {
            margin-top: 5px;
            border-radius: 10px;
            //padding: 10px 15px;
            //max-width: 500px;
            flex: 1;
          }
        }
      }
      .receivedChat {
        .messagesArea {
          div {
            color: #2e2e2e;
          }
        }
      }
      .sentChat {
        // margin-left: auto;
        flex-direction: row;
        .messagesArea {
          div {
            //background-color: #73589b;
            color: #2e2e2e;
            text-align: left;
            // width: max-content;
            //margin-left: auto;
          }
        }
      }
    }
    .HelpChat--Send {
      padding: 10px 24px;
      background-color: #fff;
      position: sticky;
      bottom: -5px;
      margin-top: auto;

      .Input--Area {
        display: flex;
        align-items: center;
        border-radius: 10px;
        border-right: 0;
        background: #f3f3f3;
        .MuiInputBase-root {
          background: transparent !important;
          &::after {
            border-bottom: 0 !important;
          }
          &::before {
            border-bottom: 0 !important;
          }
        }
        .TextBox {
          flex: 1;
          input {
            height: 51px;
            padding: 0 15px;
          }
        }
        button {
          display: flex;
          width: 100px;
          height: 51px;
          padding: 4px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex-shrink: 0;
          border-radius: 0px 8px 8px 0px;
          background: var(--primary-color);
          color: #fff;
        }
      }
    }
  }
}
