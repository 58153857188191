.SessionDashboard--StatsCards {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 10px;
  .StatsCard--Item {
    padding: 10px;
    background-color: rgba(115, 88, 155, 0.1);
    border-radius: 8px;
    h6 {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 23.4px */
    }
    .Item--Timings {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 10px;
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 130%; /* 18.2px */
        }
      }
      .Item--Tag {
        padding: 2px 6px;
        border-radius: 10px;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
      .Expert {
        background-color: #6c46a0;
        color: #fff;
      }

      .Client {
        background-color: #fff;
        color: #6c46a0;
        border: 0.5px solid #6c46a0;
      }
      .OnTime {
        background-color: #55a51d;
        color: #fff;
      }
      .Late {
        background-color: #df2a2a;
        color: #fff;
      }
    }
  }
}

.SessionDashboard--GraphCards {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;
  .GraphCard--Item {
    h6 {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 23.4px */
    }
    .Item--GraphContainer {
      height: 50px;
      &:nth-child(1) {
        background-color: rgba(108, 70, 160, 1);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:nth-child(2) {
        background-color: rgba(108, 70, 160, 0.85);
      }
      &:nth-child(3) {
        background-color: rgba(108, 70, 160, 0.75);
      }
      &:nth-child(4) {
        background-color: rgba(108, 70, 160, 0.65);
      }
      &:nth-child(5) {
        background-color: rgba(108, 70, 160, 0.5);
      }
      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 19.2px */
      }
      span {
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
      }
    }
    .Lang--Item {
      color: #fff;
      height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:nth-child(1) {
        background-color: #55a51d;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:nth-child(2) {
        background-color: #5f8cff;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 19.2px */
      }
      span {
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
      }
    }
  }
}

.sessionManagement--floatingContainer {
  position: fixed;
  right: 0;
  top: 20vh;
  z-index: 1000;
  .floatingContainer {
    display: flex;
    flex-direction: column;
    align-items: end;
    .assistantIcon {
      border-radius: 100px 0px 0px 100px;
      // background: #12b76a;
      padding: 10px;
      cursor: pointer;

      .assistantText {
        color: var(--Text-text-on-color, #fff);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

.assistant--Modal {
  .MuiBox-root {
    width: 70vw !important;
    height: 90vh !important;
    border-radius: 10px 10px 10px 10px;
  }
  .header--Tab {
    border-bottom: 2px solid var(--primary-color);
    padding: 2px;
    .tab1,
    .tab2 {
      padding: 20px 35px;
      background-color: #fff;
      color: var(--Text-text-secondary, #252422);
      text-align: center;
      font-family: Roboto;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 17px; /* 121.429% */
      border-radius: 10px 0px 0px 0px;
    }

    .selectedTab {
      background-color: var(--primary-color) !important;
      color: var(--Text-text-on-color, #fff);

      font-weight: 600;
    }

    .svg-shape::before {
      content: "";
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-right: 85px solid white;
    }

    .svg-shape::after {
      content: "";
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-left: 85px solid white;
    }
  }
  .content {
    padding: 20px 0px;
    max-height: 100%;
    // overflow-y: auto;
    .tabDetail-Holder {
      margin: 16px;
      .tabDetail {
        min-width: 100px;
        width: max-content;
        border-radius: 100px;
        padding: 8px 15px;
        white-space: nowrap;
      }
    }
    .innerTab--section {
      height: 100%;
      .innerTab--container {
        overflow-y: scroll;
        height: 73vh;
        .clinicalnotes--assistant__item {
          border-radius: 6px;
          border: 1px solid var(--Color-Black-50, #e9e8e7);
          button {
            border-radius: 8px;
            background: var(--primary-accent-color);
            border: none;
            color: var(--primary-color);
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 4px 10px;
          }
          .header {
            border-bottom: 1px solid var(--Color-Black-50, #e9e8e7);
            padding: 10px 15px;

            h5 {
              color: var(--Text-text-primary, #080807);
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          .body {
            padding: 10px 35px;
            ul {
              li {
                color: var(--Text-text-secondary, #252422);
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px; /* 162.5% */
              }
            }
          }
        }
      }
    }
    .graph--container {
      position: relative;
      overflow-y: auto;
      height: 80vh !important ;
    }
    .chat--container {
      padding: 20px;
      padding-bottom: 100px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      position: relative;
      overflow-y: auto;
      height: 75vh;
      margin-bottom: 20px;
      .sentChat {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: flex-start;
        gap: 5px;

        .textContainer {
          border-radius: 12px;
          background: #f2f2f2;

          p {
            color: var(--Text-text-primary, #080807);
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 137.5% */
          }
        }
        img {
          width: 34px;
          height: 34px;
        }
      }
      .receivedChat {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: flex-start;
        gap: 5px;

        .textContainer {
          border-radius: 12px;
          border: 1px solid var(--primary-color-opacity50);
          background: var(--primary-accent-color);

          p {
            color: var(--Text-text-primary, #080807);
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 137.5% */
          }
        }
        img {
          width: 34px;
          min-width: 34px !important;
          height: 34px;
          object-fit: cover;
        }
      }
      .chatInputContainer {
        border-radius: 10px;
        border: 1px solid #cbcbcb;
        background: #fff;
        position: fixed;
        bottom: 10px;
        left: 20px;
        right: 20px;
        padding: 20px;
        z-index: 999;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .assistant--Modal {
    .MuiBox-root {
      width: 95vw !important;
      height: 90vh !important;
      border-radius: 10px 10px 10px 10px;
    }
    .header--Tab {
      border-bottom: 2px solid var(--primary-color);
      padding: 2px;
      .tab1,
      .tab2 {
        padding: 20px 35px;
        background-color: #fff;
        color: var(--Text-text-secondary, #252422);
        text-align: center;
        font-family: Roboto;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px; /* 121.429% */
        border-radius: 10px 0px 0px 0px;
      }

      .selectedTab {
        background-color: var(--primary-color) !important;
        color: var(--Text-text-on-color, #fff);

        font-weight: 600;
      }

      .svg-shape::before {
        content: "";
        width: 0;
        height: 0;
        border-top: 50px solid transparent;
        border-right: 85px solid white;
      }

      .svg-shape::after {
        content: "";
        width: 0;
        height: 0;
        border-top: 50px solid transparent;
        border-left: 85px solid white;
      }
    }
    .content {
      padding: 20px 0px;
      max-height: 100%;
      // overflow-y: auto;
      .tabDetail-Holder {
        margin: 16px;
        .tabDetail {
          min-width: 100px;
          width: max-content;
          border-radius: 100px;
          padding: 8px 15px;
          white-space: nowrap;
        }
      }
      .innerTab--section {
        height: 100%;
        .innerTab--container {
          overflow-y: scroll;
          height: 73vh;
          .clinicalnotes--assistant__item {
            border-radius: 6px;
            border: 1px solid var(--Color-Black-50, #e9e8e7);
            button {
              border-radius: 8px;
              background: var(--primary-accent-color);
              border: none;
              color: var(--primary-color);
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              padding: 4px 10px;
            }
            .header {
              border-bottom: 1px solid var(--Color-Black-50, #e9e8e7);
              padding: 10px 15px;

              h5 {
                color: var(--Text-text-primary, #080807);
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }

            .body {
              padding: 10px 35px;
              ul {
                li {
                  color: var(--Text-text-secondary, #252422);
                  font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 26px; /* 162.5% */
                }
              }
            }
          }
        }
      }
      .graph--container {
        position: relative;
        overflow-y: auto;
        height: 80vh !important ;
      }
      .chat--container {
        padding: 20px;
        padding-bottom: 100px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        position: relative;
        overflow-y: auto;
        height: 75vh;
        margin-bottom: 20px;
        .sentChat {
          display: flex;
          flex-direction: row;
          justify-content: end;
          align-items: flex-start;
          gap: 5px;

          .textContainer {
            border-radius: 12px;
            background: #f2f2f2;

            p {
              color: var(--Text-text-primary, #080807);
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 137.5% */
            }
          }
          img {
            width: 34px;
            height: 34px;
          }
        }
        .receivedChat {
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: flex-start;
          gap: 5px;

          .textContainer {
            border-radius: 12px;
            border: 1px solid var(--primary-color-opacity50);
            background: var(--primary-accent-color);

            p {
              color: var(--Text-text-primary, #080807);
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 137.5% */
            }
          }
          img {
            width: 34px;
            min-width: 34px !important;
            height: 34px;
            object-fit: cover;
          }
        }
        .chatInputContainer {
          border-radius: 10px;
          border: 1px solid #cbcbcb;
          background: #fff;
          position: fixed;
          bottom: 10px;
          left: 20px;
          right: 20px;
          padding: 20px;
          z-index: 999;
        }
      }
    }
  }
}

.SessionDashboard--profileSummary {
  .profileSummary-Header {
    padding: 8px;
    border-bottom: 1px solid #d9d9d9;
    h6 {
      color: #000;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 20.8px */
      margin: 0;
    }

    a {
      color: var(--primary-color);
      text-align: right;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      cursor: pointer;
    }
  }

  .profileSummary--body {
    padding: 16px;

    .leftSide {
      p {
        color: var(--Color-Black-500, #52504c);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 18.2px */

        span {
          color: var(--Text-text-primary, #080807);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 18.2px */
        }

        a {
          color: var(--primary-color);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 18.2px */
          cursor: pointer;
        }
      }
    }

    .rightSide {
      p {
        color: var(--Color-Black-500, #52504c);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 18.2px */
      }

      div {
        border-radius: 10px;
        border: 1px solid var(--Color-Black-50, #e9e8e7);
        background: var(--Text-text-on-color, #fff);
        padding: 10px 20px;
        height: 160px;
        overflow-x: scroll;
      }

      ul {
        li {
          color: var(--Text-text-secondary, #252422);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
    }
  }
}

.summary--Modal {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    height: 600px !important;
  }

  .css-leqjib-MuiInputBase-root-MuiFilledInput-root {
    border-radius: 10px !important;
  }

  .SpeechTextArea {
    .css-leqjib-MuiInputBase-root-MuiFilledInput-root:before {
      border-bottom: none !important;
    }
  }

  .summaryBody {
    p {
      color: var(--Text-text-placeholder, #807c75);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
    }
    h6 {
      color: var(--Text-text-primary, #080807);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
      margin: 12px 0;
    }
    .expertNote {
      border-radius: 6px;
      border: 1px solid var(--Color-Black-100, #d5d4d2);
      background: var(--Text-text-on-color, #fff);
      padding: 10px;

      color: var(--Text-text-secondary, #252422);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px; /* 181.25% */
    }
    .medicationAccordionContainer {
      .css-o4b71y-MuiAccordionSummary-content {
        color: var(--Text-text-secondary, #252422) !important;
        font-family: Roboto !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 29px !important; /* 181.25% */
      }
      p {
        color: #807c75;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      h6 {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 20.8px */
      }
    }
  }

  .summary-footer {
    p {
      color: var(--Text-text-secondary, #252422);
      font-family: Inter !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .pagination {
      ul {
        button {
          border: 1px solid #e6e7eb;
          background: var(--Text-text-on-color, #fff);

          color: var(--Text-text-primary, #080807);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 20.8px */
        }
        .Mui-selected {
          background: #73589b;

          color: var(--Text-text-on-color, #fff);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 20.8px */
        }
      }
    }
  }
}

.recommendedTest--Modal {
  label {
    color: var(--Text-text-primary, #080807);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    margin: 8px 0;
  }

  .button1 {
    border-radius: 8px;
    background: var(--Color-Black-50, #e9e8e7);
    color: var(--Text-text-secondary, #252422);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 15.6px */
    padding: 10px 40px;
  }
  .button2 {
    border-radius: 8px;
    background: #6c46a0;
    color: var(--Text-text-on-color, #fff);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 15.6px */
    padding: 10px 40px;
  }
}

.SessionDashboard--TabsContainer {
  border-bottom: 1px solid #d9d9d9;
  //box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.37);
  .MuiTabs-indicator {
    color: var(--primary-color);
    background: var(--primary-color);
  }
  .SessionDashboard--Tabs {
    .MuiTabs-flexContainer {
      button {
        text-transform: capitalize;
        padding: 12px 10px !important;
        font-weight: 600 !important;
      }
    }
    .Mui-selected {
      color: var(--primary-color);
    }
    .css-1aquho2-MuiTabs-indicator {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      height: 4px !important;
      background-color: var(--primary-color);
    }
  }
}
.SessionDashboard--TabItemsContainer {
  .SessionTab--Panel {
    padding: 20px 10px;
  }
}

.AutoComplete--Container {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  padding: 0 10px;
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .MuiAutocomplete-endAdornment {
    button {
      display: none;
    }
  }
  & > button {
    border: none;
    border-left: 1px solid #b9b4b4;
    padding: 10px;
    background-color: transparent;
  }
  svg {
    color: #6f6b6b;
  }
  .MuiFormLabel-root {
    display: none;
  }
}
.SessionPrescription--Table,
.SessionLabAdvice--Table {
  width: 100%;
  padding: 10px;
  tr {
    & > * + * {
      margin-left: 0.5rem; /* 8px */
    }
  }
  .Table--Head {
    div {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 18.2px */
    }
  }
  .Table--Head,
  .Table--Body {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .Table-span-1 {
    grid-column: span 1 / span 1;
  }
  .Table-span-2 {
    grid-column: span 2 / span 2;
  }
  .Table-span-3 {
    grid-column: span 3 / span 3;
  }
  .Table-span-4 {
    grid-column: span 4 / span 4;
  }
  .Table-span-5 {
    grid-column: span 5 / span 5;
  }
  .Table-span-6 {
    grid-column: span 6 / span 6;
  }
  .recommendedLabTest--Container {
    h6 {
      color: #000;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 18.2px */
      margin: 0;
    }
    .recommendedLabTestDiv {
      border-radius: 100px;
      background: rgba(108, 70, 160, 0.1);
      padding: 8px;
      color: #6c46a0;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      cursor: pointer;
    }
  }
}

.PrescriptionOtherData--Container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  .OtherData--Item {
    & > div {
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 8px;
      margin-top: 10px;
      .rdw-editor-main {
        padding: 10px;
        max-height: 200px;
        min-height: 200px;
      }
    }
  }
}

.generated-prescription-container {
  background: rgba(255, 255, 255, 0.29);
  border-radius: 16px;
  //box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  //backdrop-filter: blur(10.2px);
  //-webkit-backdrop-filter: blur(10.2px);
  border: 1px solid rgba(255, 255, 255, 1);

  .rightSideDiv {
    border-radius: 6px;
    background: rgba(115, 88, 155, 0.1);
    cursor: pointer;

    color: #73589b;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .prescriptionUploadDiv {
    .browseButton {
      border-radius: 6px;
      border: 1px solid var(--gray-300, #d1d5db);
      background: var(--Text-text-on-color, #fff);
      width: fit-content;

      /* shadow/sm */
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
      .prescriptionUploadLeft {
        color: var(--Text-text-placeholder, #807c75);
        font-family: Roboto !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        white-space: nowrap;
      }

      .uploadBtnTextArea {
        border-radius: 0px 6px 6px 0px;
        border-left: 1px solid var(--gray-300, #d1d5db);
        background: var(--gray-50, #f9fafb);
      }
    }

    .uploadButton {
      border-radius: 6px;
      background: var(--primary-color);
      border: none;

      color: var(--Text-text-on-color, #fff);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 15.6px */
      padding: 0 19px;
    }

    .uploadedFileContainer {
      border-radius: 6px;
      border: 1px solid var(--gray-300, #d1d5db);
      background: var(--Text-text-on-color, #fff);

      /* shadow/sm */
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

      p {
        color: #0f0f0f;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
      }
    }

    .rightSide {
      border-radius: 6px;
      background: rgba(115, 88, 155, 0.1);

      color: #73589b;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.SessionPrescription--box {
  box-shadow: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.session-box-blink {
  animation: blink 3s infinite ease-in;
}

@keyframes blink {
  0% {
    // background-color: rgba(107, 70, 160, 0.6);
    box-shadow: 0px 4px 16px -5px rgb(107, 70, 160);
    // border: 4px solid rgb(107, 70, 160);
  }
  50% {
    box-shadow: 0px 4px 16px -5px rgb(180, 138, 240);
    // background-color: rgba(107, 70, 160, 0.4);
    // border: 4px solid rgba(107, 70, 160, 0.4);
  }
  100% {
    box-shadow: 0px 4px 16px -5px rgb(107, 70, 160);
    // background-color: rgba(108, 70, 160, 0.6);
    // border: 4px solid rgb(107, 70, 160);
  }
}

.recorder {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.session-stat-OnTime {
  color: #55a51d;
  background-color: #55a51d1a;
}
.session-stat-Late {
  color: #df2a2a;
  background-color: #df2a2a1a;
}

.clinicalDashboard--scroll {
  ::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e1e1e1; /* Color of the scrollbar handle */
    border-radius: 6px; /* Border radius of the scrollbar handle */
  }
}

// book loader animation

:root {
  --hue: 258; /* Converted HSL hue for #73589B */
  --bg: hsl(var(--hue), 80%, 90%);
  --fg: hsl(var(--hue), 10%, 50%);
  --primary: hsl(var(--hue), 90%, 55%);
  --primary-l: hsl(var(--hue), 90%, 65%);
  --primary-d: hsl(var(--hue), 90%, 45%);
  --white: hsl(var(--hue), 80%, 95%);
  --white-d: hsl(var(--hue), 80%, 80%);
}

/* Rest of the CSS remains unchanged */

body {
}
.book,
.book__pg-shadow,
.book__pg {
  animation: cover 7s ease-in-out infinite;
}
.book {
  background-color: var(--primary-l);
  z-index: 100000;
  border-radius: 0.25em;

  /* box-shadow:
    0 0.25em 0.5em hsla(0,0%,0%,0.3),
    0 0 0 0.25em var(--primary) inset; */
  padding: 0.25em;
  perspective: 37.5em;

  width: 8em;
  height: 6em;
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
}
.book__pg-shadow,
.book__pg {
  position: absolute;
  left: 0.25em;
  width: calc(50% - 0.25em);
}
.book__pg-shadow {
  animation-name: shadow;
  background-image: linear-gradient(
    -45deg,
    hsla(0, 0%, 0%, 0) 50%,
    hsla(0, 0%, 0%, 0.3) 50%
  );
  filter: blur(0.25em);
  top: calc(100% - 0.25em);
  height: 3.75em;
  transform: scaleY(0);
  transform-origin: 100% 0%;
}
.book__pg {
  animation-name: pg1;
  background-color: var(--white);
  background-image: linear-gradient(
    90deg,
    hsla(var(--hue), 10%, 90%, 0) 87.5%,
    hsl(var(--hue), 10%, 90%)
  );
  height: calc(100% - 0.5em);
  transform-origin: 100% 50%;
}
.book__pg--2,
.book__pg--3,
.book__pg--4 {
  background-image: repeating-linear-gradient(
      hsl(var(--hue), 10%, 10%) 0 0.125em,
      hsla(var(--hue), 10%, 10%, 0) 0.125em 0.5em
    ),
    linear-gradient(
      90deg,
      hsla(var(--hue), 10%, 90%, 0) 87.5%,
      hsl(var(--hue), 10%, 90%)
    );
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2.5em 4.125em, 100% 100%;
}
.book__pg--2 {
  animation-name: pg2;
}
.book__pg--3 {
  animation-name: pg3;
}
.book__pg--4 {
  animation-name: pg4;
}
.book__pg--5 {
  animation-name: pg5;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 10%, 30%);
    --fg: hsl(var(--hue), 10%, 90%);
  }
}

/* Animations */
@keyframes cover {
  from,
  5%,
  45%,
  55%,
  95%,
  to {
    animation-timing-function: ease-out;
    background-color: var(--primary-l);
  }
  10%,
  40%,
  60%,
  90% {
    animation-timing-function: ease-in;
    background-color: var(--primary-d);
  }
}
@keyframes shadow {
  from,
  10.01%,
  20.01%,
  30.01%,
  40.01% {
    animation-timing-function: ease-in;
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(0);
  }
  5%,
  15%,
  25%,
  35%,
  45%,
  55%,
  65%,
  75%,
  85%,
  95% {
    animation-timing-function: ease-out;
    transform: translate3d(0, 0, 1px) scaleY(0.2) rotateY(90deg);
  }
  10%,
  20%,
  30%,
  40%,
  50%,
  to {
    animation-timing-function: ease-out;
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(180deg);
  }
  50.01%,
  60.01%,
  70.01%,
  80.01%,
  90.01% {
    animation-timing-function: ease-in;
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(180deg);
  }
  60%,
  70%,
  80%,
  90%,
  to {
    animation-timing-function: ease-out;
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(0);
  }
}
@keyframes pg1 {
  from,
  to {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0.4deg);
  }
  10%,
  15% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(180deg);
  }
  20%,
  80% {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(180deg);
  }
  85%,
  90% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(180deg);
  }
}
@keyframes pg2 {
  from,
  to {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(0.3deg);
  }
  5%,
  10% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0.3deg);
  }
  20%,
  25% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(179.9deg);
  }
  30%,
  70% {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(179.9deg);
  }
  75%,
  80% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(179.9deg);
  }
  90%,
  95% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0.3deg);
  }
}
@keyframes pg3 {
  from,
  10%,
  90%,
  to {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(0.2deg);
  }
  15%,
  20% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0.2deg);
  }
  30%,
  35% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(179.8deg);
  }
  40%,
  60% {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(179.8deg);
  }
  65%,
  70% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(179.8deg);
  }
  80%,
  85% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0.2deg);
  }
}
@keyframes pg4 {
  from,
  20%,
  80%,
  to {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(0.1deg);
  }
  25%,
  30% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0.1deg);
  }
  40%,
  45% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(179.7deg);
  }
  50% {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(179.7deg);
  }
  55%,
  60% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(179.7deg);
  }
  70%,
  75% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0.1deg);
  }
}
@keyframes pg5 {
  from,
  30%,
  70%,
  to {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(0);
  }
  35%,
  40% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0deg);
  }
  50% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(179.6deg);
  }
  60%,
  65% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0);
  }
}

.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: fit-content;
  overflow-wrap: break-word;
}
.sc-eqUAAy {
  .file-types {
    display: none;
  }
}
.Notetaker--FileContainer {
  span {
    font-size: large !important;
  }
}
.assistant-message-response {
  background-color: rgb(209, 209, 209);
  width: max-content;
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 9px;
    margin: -13px auto;
    text-align: center;
    div {
      width: 9px;
      height: 9px;
      background-color: white;
      border-radius: 100%;
      display: inline-block;
      animation: bouncedelay 1.4s infinite ease-in-out;
    }
    div:nth-child(1) {
      animation-delay: -0.32s;
    }
    div:nth-child(2) {
      animation-delay: -0.16s;
    }
  }

  #loading-bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 37.6px;
    min-width: 73px;
    border-radius: 10px;
    position: relative;
  }
  @keyframes bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
}
.date--picker {
  .MuiInputBase-input {
    padding: 8.5px 32px 8.5px 14px;
  }
}
