.NotificationTabs {
  border: none;
  gap: 10px;
  padding: 10px 20px;
  .nav-link {
    transition: none;
    &:hover {
      border: none;
    }
  }
  .nav-link {
    color: #153300 !important;
    font-size: 14px;
    border: 1px solid #d9d9d9;
    border-radius: 50px;
  }
  .nav-item {
    .nav-link.active {
      background-color: #153300;
      color: #fff !important;
    }
  }
}

.NotificationPanel {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  & > * + * {
    border-top-width: 1px;
    border-bottom-width: 0px;
    border-style: solid;
    border-color: #d9d9d9;
  }
}
.NotificationsWidget {
  .tab-content > .active {
    padding-bottom: 30px;
  }
}
