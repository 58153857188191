.PrimaryCTA {
  border-radius: 10px !important;
  border: none;
  background-color: var(--primary-color) !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #fff !important;
  text-transform: capitalize !important;
  &:hover {
    background-color: var(--primary-color-darken) !important;
  }
}
.PrimaryCTA---ALT {
  border-radius: 10px !important;
  border: none;
  background: var(--primary-accent-color);
  color: var(--primary-color) !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  text-transform: capitalize !important;
  &:hover {
    background-color: var(--primary-color-opacity50) !important;
  }
}
.PrimaryCTA.Mui-disabled {
  background-color: var(--primary-color-opacity50) !important;
}
.PrimaryCTA {
  &:disabled {
    background-color: var(--primary-color-opacity50) !important;
  }
}

.PrimaryCTA---ALT {
  border-radius: 10px !important;
  border: none;
  background: var(--primary-accent-color);
  color: var(--primary-color) !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  text-transform: capitalize !important;
  &:hover {
    background-color: var(--primary-color-opacity50) !important;
  }
}

.PrimaryCTA--Outlined {
  border-radius: 10px !important;
  background-color: #fff !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  text-transform: capitalize !important;
}
.PrimaryCTA--Outlined-success {
  border-radius: 10px !important;
  background-color: #fff !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #17b26a !important;
  border: 1px solid #17b26a !important;
  text-transform: capitalize !important;
}
.PrimaryCTA--Outlined.Mui-disabled {
  background-color: #fbfbfb8f !important;
}
.PrimaryCTA--Ghost {
  border-radius: 10px !important;
  background-color: #fff !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: var(--primary-color) !important;
  text-transform: capitalize !important;
}
.PrimaryCTA--Ghost.Mui-disabled {
  background-color: #fbfbfb8f !important;
}

.GreenCTA {
  display: flex;
  height: 50px !important;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px !important;
  background-color: #9dea66 !important;
  color: #21231e !important;
  text-transform: capitalize !important;
}

.GreenCTA.Mui-disabled {
  background-color: rgb(157 234 102 / 26%) !important;
  color: #666666 !important;
}

.RedCTA {
  display: flex;
  height: 50px !important;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px !important;
  background: #dd263c !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.DarkCTA {
  border-radius: 10px !important;
  background-color: #111 !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #fff !important;
  text-transform: capitalize !important;
  &:hover {
    background-color: darken(#111, 5%) !important;
  }
}

.DarkCTA--Outlined {
  border-radius: 10px !important;
  background-color: #fff !important;
  border: 1px solid #111 !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #111 !important;
  text-transform: capitalize !important;
}
