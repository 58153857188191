.Pagination {
  ul {
    button {
      border: 1px solid #e6e7eb;
      background: var(--Text-text-on-color, #fff);

      color: var(--Text-text-primary, #080807);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 20.8px */
    }
    .Mui-selected {
      background: #73589b;

      color: var(--Text-text-on-color, #fff);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 20.8px */
    }
  }
}

.Table-Footer-p {
    color: var(--Text-text-secondary, #252422);
    font-family: Inter !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }