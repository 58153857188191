.PayoutWidget {
  border-radius: 10px;
  background: #fff;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  .PayoutWidgetCard--Header {
    display: flex;
    align-items: center;
    gap: 16px;

    svg {
      width: 35px;
      height: 35px;
      fill: #6A6C6A;
    }

    span {
      color: #6a6c6a;
      font-size: 16px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }

  h6 {
    color: #000;
    font-size: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }
}
