.SettingsDashboard {
  .ExpertSettingsDashboard--Header {
    h2 {
      color: #000;
      font-size: 22px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }
  }
}

.ExpertSettingsDashboard--Options {
  justify-content: space-between;

  .Options {
    .Option--Item {
      padding: 11px 25px;
      border-radius: 30px;
      border: 1px solid #d9d9d9;
      background: #f5f5f5;
      color: #6a6c6a;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .SelectedItem {
      background: #73589b;
      color: #fff;
    }
  }
}
.ExpertSettingsDashboard--Content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  h4 {
    color: #000;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }

  h6 {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    margin-top: 20px;
  }

  .css-1xhypcz-MuiStack-root {
    padding-top: 0 !important;
  }
}

.UploadedFiles--Section {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  gap: 10px;

  .UploadedItem {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 10px;
      border: 2px solid #d9d9d9;
      padding: 2px;
      aspect-ratio: 1/1;
    }

    p {
      margin-top: 5px;
      color: #21231e;
      text-align: center;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }
}

.Content--Section {
  & > span {
    color: #000;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}

.Stella--Mode {
  &__Table {
    .heading-text {
      color: #6b7280;
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
    }
    .link {
      display: flex; // Keeps items in a row
      align-items: center; // Aligns items vertically
      gap: 0.25rem; // Maintains space between items
      padding: 0.25rem; // Padding around the content
    
      .text-container {
        flex: 1; // Takes up the remaining space
        max-width: calc(30ch); // Approx width for 30 characters
        overflow: hidden; // Hides overflow
        white-space: nowrap; // Prevents text wrapping
        text-overflow: ellipsis; // Adds ellipsis when text overflows
      }
    
      .copy-icon {
        flex-shrink: 0;
        border-radius: 4px;
        border: 1px solid #e4e4e4;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    
        svg {
          width: 16.8px;
          height: 16.8px;
          color: #414141;
        }
      }
    }
    
    
    
    
    .row-qrcode {
      display: flex;
      height: 36px;
      width: 60px;
      border-radius: 8px;
      border: 1px solid var(--Light-Grey-2, rgba(28, 27, 31, 0.12));
      background: #fff;
      .qr-code-icon {
        width: 30px;
        height: 26px;
        padding: 4px;
      }
      .download-icon {
        display: flex;
        justify-content: center; // Horizontally center the icon
        align-items: center; // Vertically center the icon
        width: 34px;
        height: 36px;
        border-radius: 0px 8px 8px 0px;
        border: 1px solid #e4e4e4;
        background: #f3f3f3;
      }
    }
  }
}


.Add-Stella-Mode{
  .MuiPaper-root{
    min-width: 600px;
    max-height: 600px;
    min-height: 500px;
  }
}