.client-details-dialog {
  .MuiDialog-paper {
    min-width: 600px !important;
    max-width: 600px !important;
    // min-height:  !important;
    max-height: 600px !important;
  }
}
.session-details-dialog {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    min-width: 80vw !important;
  }
}
.notetaker-new-dialog {
  .MuiDialog-paper {
    min-width: 60vw !important;
    height: 80vh !important;
    border-radius: 10px 10px 10px 10px;
  }
}
@media screen and (max-width: 1024px) {
  .client-details-dialog {
    .MuiDialog-paper {
      min-width: 95% !important;
      max-width: 95% !important;
    }
  }
  .notetaker-new-dialog {
    .MuiDialog-paper {
      min-width: 95% !important;
      max-width: 95% !important;
    }
  }
}
